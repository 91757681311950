import React, { ReactNode } from 'react';

import styles from './Label.module.css';

export interface LabelProps {
  children: ReactNode;
  htmlFor: string;
}

export function Label({ children, htmlFor }: LabelProps) {
  return (
    <label className={styles['Label']} htmlFor={htmlFor}>
      {children}
    </label>
  );
}
