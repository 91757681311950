import React from 'react';
import cx from 'classnames';
import styles from './Input.module.css';
import taStyles from './Input.module.css';

export interface TextareaProps {
  id: string;
}

export function Textarea(props) {
  return <textarea className={cx(styles['Input'], taStyles['textarea'])} {...props} />;
}
