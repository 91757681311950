import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import styles from './Input.module.css';

export interface CustomHtmlInputElement extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
}

export function Input({ errorMessage, type = 'text', ...rest }: CustomHtmlInputElement) {
  const defaultInputClassName = styles['Input'];
  const inputClassName = errorMessage ? cx(defaultInputClassName, styles['Input--error']) : defaultInputClassName;
  const errorMessageClassName = errorMessage ? styles['Input--error-message'] : null;

  return (
    <>
      <input className={inputClassName} type={type} {...rest} />
      {errorMessage ? <div className={errorMessageClassName}>{errorMessage}</div> : null}
    </>
  );
}
