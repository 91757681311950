import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './ButtonGroup.module.css';

export interface ButtonGroupProps {
  children: ReactNode;
  className?: string;
}

export function ButtonGroup({ children, className }: ButtonGroupProps) {
  return <div className={cx(styles['ButtonGroup'], className)}>{children}</div>;
}
