import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import React from 'react';

export interface ActionsProps {
  disabled?: boolean;
}

export function Actions({ disabled = false }: ActionsProps) {
  return (
    <ButtonGroup>
      <Button type="reset" color="secondary" disabled={disabled} style="squared">
        Reset
      </Button>
      <Button type="submit" disabled={disabled} style="squared">
        Save
      </Button>
    </ButtonGroup>
  );
}
